import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import "./Faqs.scss";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Accordion from "react-bootstrap/Accordion";

const Faqs = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activeKey, setActiveKey] = useState("ideal-customer-profile");

  const faqData = {
    "ideal-customer-profile": [
      {
        question: "What is VAIS?",
        answer:
          "Valasys AI Score (VAIS) is a propensity score that measures how closely a company aligns with your product. The higher the score, the more ideal the company is for your product.",
      },
      {
        question: "How is VAIS generated?",
        answer:
          "VAIS is generated through a combination of market research and advanced modeling. Our team reviewed over 50,000 products in more than 300 subcategories and conducted extensive surveys with over 10,000 decision-makers. This data is used to train the model to determine the relevance between a product and an industry, assigning weights to company firmographic details like employee size, revenue, and industry priority. Custom rules created by our market research team further refine the score.",
      },
      {
        question: "What is the purpose of VAIS?",
        answer:
          "The purpose of VAIS is to help businesses identify potential customers who are most likely to be interested in their product, thereby optimizing marketing efforts and increasing the likelihood of successful sales.",
      },
      {
        question: "What is Data Enrichment?",
        answer:
          "Data enrichment involves enhancing, refining, and improving raw data by adding additional relevant information, making it more useful and actionable.",
      },
      {
        question: "How does Valasys AI enrich data?",
        answer:
          "Valasys AI enriches data by leveraging its extensive database of 181 million data points, including direct dials, email connects, and LinkedIn URLs. The data is continuously scraped, cleaned, filtered, and segmented to ensure its accuracy and relevance.",
      },
      {
        question:
          "What are the benefits of using Valasys AI for data enrichment?",
        answer:
          "The benefits include improved data accuracy, enhanced targeting capabilities, higher conversion rates, reduced time and resources spent on data management, and more efficient lead generation and sales processes.",
      },
    ],
    "account-based-marketing": [
      {
        question: "What is Account-Based Marketing (ABM)?",
        answer:
          "ABM is a strategic marketing approach that focuses on targeting specific high-value accounts, rather than a broad audience, to create personalized campaigns that resonate with key decision-makers.",
      },
      {
        question: "How does Valasys AI support ABM?",
        answer:
          "If you have an existing list of accounts and you want to validate and verify that, simply upload a list of domains, and Valasys AI will screen and score your accounts to help you prioritize. ",
      },
      {
        question: "What is ABM list verification?",
        answer:
          "ABM list verification involves ensuring that the accounts on your ABM list are accurate, up-to-date, and relevant to your targeting criteria, reducing wasted efforts on incorrect or outdated information.",
      },
      {
        question: "How can Valasys AI improve ABM campaigns?",
        answer:
          "Valasys AI improves ABM campaigns by providing precise targeting through VAIS, identifying high-intent accounts through intent signals, and enriching data for better accuracy and relevancy, leading to higher engagement and conversion rates.",
      },
    ],
    "look-a-likes": [
      {
        question: "What is Look-Alike Generation",
        answer:
          "Look-alike generation involves creating profiles of potential customers that resemble your best existing customers, allowing for more effective targeting of new prospects.",
      },
      {
        question: "How does Valasys AI create look-alike models?",
        answer:
          "Valasys AI creates look-alike models by analyzing data from top-performing clients and identifying common characteristics and patterns. These insights are used to find and target similar prospects.",
      },
      {
        question: "What are the benefits of using look-alike models?",
        answer:
          "Benefits include increased efficiency in identifying high-potential leads, improved targeting accuracy, and higher conversion rates due to the relevance of the targeted prospects.",
      },
    ],
    prospects: [
      {
        question: "What is the Prospect Download feature?",
        answer:
          "The Prospect Download feature allows users to download detailed information about potential leads, including contact details, firmographic data, and intent signals.",
      },
      {
        question: "How can I use the Prospect Download feature?",
        answer:
          "You can use the Prospect Download feature by selecting the desired prospects from Valasys AI's database and downloading the information in a suitable format for your marketing and sales activities.",
      },
      {
        question:
          "What type of information can I expect from a Prospect Download?",
        answer:
          "Information typically includes contact details (such as email addresses and phone numbers), firmographic data (like company size, industry, and revenue), and insights into their intent and readiness to purchase.",
      },
    ],
    "data-security": [
      {
        question: "How does Valasys AI ensure data security?",
        answer:
          "Valasys AI ensures data security through robust encryption methods, regular security audits, compliance with data protection regulations, and secure data handling practices to protect sensitive information.",
      },
      {
        question: "What compliance measures are in place for data security?",
        answer:
          "Valasys AI complies with data protection regulations such as GDPR and CCPA, ensuring that all data handling processes adhere to the highest standards of privacy and security.",
      },
      {
        question: "How is user data protected from unauthorized access?",
        answer:
          "User data is protected through multiple layers of security, including encryption, access controls, and regular monitoring to prevent unauthorized access and breaches",
      },
    ],
    // "other-topics": [
    //   {
    //     question: "What are Other Topics?",
    //     answer:
    //       "Other topics refer to miscellaneous FAQs that do not fit into the predefined categories. These can include general questions about the company, its services, industry trends, and other relevant information.",
    //   },
    // ],
    "predictive-analytics": [
      {
        question: "What is Predictive Analytics?",
        answer:
          "Predictive analytics involves using historical data, statistical algorithms, and machine learning techniques to identify the likelihood of future outcomes based on past data.",
      },
      {
        question: "How does Valasys AI use predictive analytics?",
        answer:
          "Valasys AI uses predictive analytics to evaluate various parameters such as market performance, demand, and firmographic details, generating the Valasys AI Score (VAIS) to identify potential customers.",
      },
      {
        question:
          "What are the benefits of predictive analytics for businesses?",
        answer:
          "Benefits include improved decision-making, better targeting of marketing efforts, higher conversion rates, and optimized resource allocation.",
      },
    ],
    "natural-language-processing": [
      {
        question: "What is Natural Language Processing (NLP)?",
        answer:
          "NLP is a branch of artificial intelligence that focuses on the interaction between computers and humans through natural language, enabling machines to understand and process human language.",
      },
      {
        question: "How does Valasys AI use NLP?",
        answer:
          "Valasys AI in collaboration with Bombora uses NLP to scrape online footprints and generate intent signals, indicating a company's buying stage and likelihood of purchase.",
      },
      {
        question: "What are the advantages of using NLP in lead generation?",
        answer:
          "Advantages include the ability to understand and interpret large volumes of unstructured data, identify high-intent leads, and provide more accurate and timely insights for marketing and sales strategies.",
      },
    ],
    "machine-learning": [
      {
        question: "How does Valasys AI utilize machine learning?",
        answer:
          "Valasys AI utilizes machine learning through supervised, unsupervised, and reinforcement learning techniques to analyze data, predict market trends, and continuously improve its models for better accuracy and efficiency.",
      },
      {
        question:
          "What are the key machine learning techniques used by Valasys AI?",
        answer:
          "Key techniques include logistic regression, K-nearest neighbor, linear regression, K-means clustering, expectation maximization, and Q-learning.",
      },
      {
        question: "How does machine learning benefit Valasys AI users?",
        answer:
          "Machine learning benefits users by providing more accurate insights, improving lead scoring and targeting, and enabling the platform to adapt and improve over time for better results.",
      },
    ],
    "data-management": [
      {
        question: "What is a Data Management Platform (DMP)?",
        answer:
          "A DMP is a centralized platform that collects, organizes, and analyzes large sets of data from various sources to help businesses make informed decisions and improve marketing efforts.",
      },
      {
        question: "How does Valasys AI function as a DMP?",
        answer:
          "Valasys AI functions as a DMP by continuously scraping, cleaning, filtering, and segmenting data to ensure it remains accurate and actionable, supporting programmatic advertising and targeted marketing campaigns.",
      },
      {
        question: "What are the benefits of using Valasys AI as a DMP?",
        answer:
          "Benefits include streamlined data management, improved data quality, enhanced targeting capabilities, and more efficient marketing and sales processes.",
      },
    ],
    "intent-data": [
      {
        question: "What is intent data?",
        answer:
          "Intent data is information collected about a user’s online activities that indicate their interest in a particular product or service. It helps businesses understand potential customers' interests and buying intent.",
      },
      {
        question: "How does Valasys AI utilize intent data?",
        answer:
          "Valasys AI leverages intent data to identify companies showing signs of interest in your products or services. This data is integrated into the platform to provide insights that can help prioritize and target high-intent accounts.",
      },
      {
        question:
          "What is Bombora and how does it fit into Valasys AI's offering?",
        answer:
          "Bombora is a leading provider of B2B intent data. By partnering with Bombora, Valasys AI integrates Bombora's comprehensive intent data into its platform, enhancing the ability to identify and target companies that are actively researching relevant topics.",
      },
      {
        question: "How can intent data improve marketing and sales efforts?",
        answer:
          "Intent data allows marketing and sales teams to focus their efforts on prospects who are more likely to be interested in their offerings, leading to more efficient campaigns, higher engagement rates, and improved conversion rates.",
      },
      {
        question: "What types of intent signals does Bombora provide?",
        answer:
          "Bombora provides various types of intent signals, including content consumption patterns, topic engagement, and research activity across multiple sources. These signals help identify companies that are in the market for specific products or services.",
      },
      {
        question: "How frequently is intent data updated in Valasys AI?",
        answer:
          "Intent data in Valasys AI is updated regularly to ensure that users have access to the most current information, enabling timely and effective decision-making.",
      },
      {
        question: "Can I customize the intent data to match my specific needs?",
        answer:
          "Yes, Valasys AI allows for the customization of intent data to match your specific industry, products, and target market, ensuring the most relevant insights for your business.",
      },
    ],
    "suppression-template": [
      {
        question: "What is a suppression template?",
        answer:
          "A suppression template is a list or set of criteria used to exclude certain contacts or accounts from marketing campaigns. This ensures that efforts are not wasted on uninterested or irrelevant targets.",
      },
      {
        question: "How does Valasys AI handle suppression templates?",
        answer:
          "Valasys AI allows users to upload and manage suppression templates to refine their targeting strategies, ensuring campaigns are focused on the most promising leads and avoiding any undesirable contacts.",
      },
      {
        question: "Can I update my suppression template regularly?",
        answer:
          "Yes, you can update your suppression template as often as needed to reflect changes in your targeting strategy or business priorities.",
      },
      {
        question: "What are the benefits of using a suppression template?",
        answer:
          "The benefits include improved campaign efficiency, reduced marketing spending, and better compliance with regulations by avoiding contacts who have opted out or are irrelevant.",
      },
    ],
    packages: [
      {
        question: "What packages does Valasys AI offer?",
        answer:
          "Valasys AI offers three main packages: Starter, Premium, and Elite, each designed to cater to different business needs and budgets.",
      },
      {
        question: "What is included in the Starter package?",
        answer:
          "The Starter package includes 7,000 data download credits, 500 prospect data credits, 90 searches, visibility for up to 7 pages, and email support. Additional credits are chargeable at $1.50 per credit, and there is a daily data download limit of 500.",
      },
      {
        question: "What additional features does the Premium package offer?",
        answer:
          "The Premium package includes 14,000 data download credits, 1,000 prospect data credits, 180 searches, visibility for up to 14 pages, email support, 2 licenses, and a daily data download limit of 1,000.",
      },
      {
        question: "What are the benefits of the Elite package?",
        answer:
          "The Elite package includes 28,600 data download credits, 2,000 prospect data credits, 270 searches, visibility for up to 21 pages, high-priority support, 5 licenses, and a daily data download limit of 2,000.",
      },
      {
        question: "What is the pricing for each package?",
        answer:
          "The Starter package is priced at $25,000, the Premium package at $50,000, and the Elite package at $100,000.",
      },
      {
        question: "Are there any add-on benefits available?",
        answer:
          "Yes, add-on benefits include additional opt-in leads, additional leads, replacement leads, and over-and-above CPL options. These benefits vary by package.",
      },
      {
        question: "Can I upgrade my package later?",
        answer:
          "Yes, you can upgrade your package at any time to access more features and benefits as your business needs grow.",
      },
    ],
    "support-tickets": [
      {
        question: "How do I raise a support ticket?",
        answer:
          "To raise a support ticket, log in to your Valasys AI account and navigate to the support section. Click on `Raise a Ticket` and fill in the required details about your issue or query. Once submitted, our support team will get back to you as soon as possible.",
      },
      {
        question:
          "What information do I need to provide when raising a support ticket?",
        answer:
          "When raising a support ticket, please provide detailed information about the issue you are experiencing, including steps to reproduce the issue, screenshots (if applicable), and any error messages you have encountered. The more information you provide, the quicker we can resolve your issue.",
      },
      {
        question: "How can I track the status of my support ticket?",
        answer:
          "You can track the status of your support ticket by logging into your Valasys AI account and visiting the support section. Here, you will see a list of all your open tickets and their current status. You will also receive email notifications for any updates on your ticket.",
      },
      {
        question: "What are the response times for support tickets?",
        answer:
          "Our standard response times vary based on the package you have subscribed to. For Starter and Premium packages, you can expect a response within 24-48 hours. For Elite package subscribers, we offer high-priority support with responses typically within a few hours.",
      },
      {
        question: "Can I raise a support ticket for product feature requests?",
        answer:
          "Yes, you can raise a support ticket for product feature requests. Our team values customer feedback and will review all feature requests for potential inclusion in future updates.",
      },
      {
        question: "What should I do if my issue is urgent?",
        answer:
          "If your issue is urgent and you require immediate assistance, please indicate the urgency in your support ticket by selecting the appropriate priority level. Elite package subscribers can also utilize high-priority support for faster response times.",
      },
      {
        question: "Can I contact support via phone or email?",
        answer:
          "Yes, in addition to raising a support ticket, you can contact our support team via phone or email. The contact details are available in the support section of your Valasys AI account. For Elite package subscribers, high-priority support includes direct phone assistance.",
      },
      {
        question: "What if my issue is not resolved to my satisfaction?",
        answer:
          "If your issue is not resolved to your satisfaction, you can escalate the ticket by responding to the support ticket thread or by contacting our support team directly. We are committed to resolving all issues promptly and to your satisfaction.",
      },
      {
        question: "How can I provide feedback on the support I received?",
        answer:
          "After your support ticket is resolved, you will receive a follow-up email asking for feedback on your support experience. We appreciate your feedback as it helps us improve our services.",
      },
    ],
    "best-practices": [
      {
        question: "Align Intent Topics with Sub-Products",
        answer:
          "Ensure that the selected Intent Topics are closely related to your specific sub-products. This alignment guarantees that the insights generated will be highly relevant and actionable.",
      },
      {
        question: "Precision is Key",
        answer:
          "The accuracy and relevance of the selected topics directly impact the quality of the results. The closer the topics are to your sub-product, the more precise the targeting and insights will be.",
      },
      {
        question: "Optimal Topic Selection",
        answer:
          "Select up to 12 Intent Topics to achieve the best results. If you require additional options for topic selection, please reach out to us at <a href=mailto:support@valasys.ai target=`_blank`>support@valasys.ai</a> for assistance.",
      },
      {
        question: "Three Methods for Selecting Intent Topics",
        answer: `
          ◦ a. Keyword Search:<br />
          &nbsp;&nbsp;&nbsp;▪ Type in your Product Sub-Category or any relevant term associated with your product. Select the most relevant topics from the list that is automatically populated.<br /><br />
          ◦ b. Category & Theme Navigation:<br />
          &nbsp;&nbsp;&nbsp;▪ Browse through categories and themes to find topics that align with your product. This method allows for a structured and systematic selection process.<br /><br />
          ◦ c. Product URL Search (Recommended):<br />
          &nbsp;&nbsp;&nbsp;▪ Use the Product URL search feature for the most accurate topic selection. Simply enter your website URL or a competitor’s URL, and the platform will automatically generate a list of relevant topics based on the content of the URL.`,
      },
      {
        question: "Stay Aligned",
        answer:
          "Avoid selecting topics that do not align with your chosen product subcategory. Misaligned topics can lead to inaccurate targeting and less effective campaign outcomes.",
      },
    ],
    "vais-intent": [
      {
        title: "Interpreting VAIS + Intent",
        headers: ["VAIS", "Intent", "Insight", "Action"],
        rows: [
          {
            vais: "High",
            intent: "High",
            insight:
              "In-Depth Research: Prospects are conducting deep, detailed research about your product.",
            action:
              "Direct Engagement: Initiate personalized demos or discussions.",
          },
          {
            vais: "High",
            intent: "Medium",
            insight:
              "Active Research: Prospects are actively seeking information but aren't yet ready to make a purchase decision.",
            action:
              "Targeted Campaigns: Deploy ads, personalized emails, and content that addresses specific pain points to move them closer to conversion.",
          },
          {
            vais: "High",
            intent: "Low",
            insight:
              "Early Research: Prospects are in the initial stages of gathering information, exploring the market landscape.",
            action:
              "Nurture Leads: Provide educational content like blogs, whitepapers, or webinars to guide them through their buyer journey and build trust.",
          },
          {
            vais: "Medium",
            intent: "High",
            insight:
              "Intent-Driven: Prospects show clear intent through consistent engagement but may need more time or information to decide.",
            action:
              "Nurture Campaigns: Maintain engagement with valuable insights through regular touchpoints until they are ready to convert.",
          },
          {
            vais: "Medium",
            intent: "Medium",
            insight:
              "Moderate Research: Prospects are gathering general information.",
            action:
              "Educational Outreach: Provide informative content to guide their research.",
          },
          {
            vais: "Medium",
            intent: "Low",
            insight:
              "Passive Interest: Prospects occasionally engage, likely unsure about their needs or available solutions.",
            action:
              "Awareness Initiatives: Run broad awareness campaigns, boost social media presence, and offer top-of-funnel content to spark further interest.",
          },
          {
            vais: "Low",
            intent: "High",
            insight:
              "Breakout: Prospects show sudden bursts of engagement, indicating a potential shift in interest or needs.",
            action:
              "Qualify Further: Trigger qualification processes to assess readiness and tailor follow-up actions, possibly moving them to a higher intent category.",
          },
          {
            vais: "Low",
            intent: "Medium",
            insight:
              "Occasional Interest: Prospects show inconsistent interest, possibly due to external factors or shifting priorities.",
            action:
              "Engagement Tactics: Deploy remarketing ads or email campaigns to rekindle interest and gather more insights about their needs.",
          },
          {
            vais: "Low",
            intent: "Low",
            insight:
              "Limited Research: Prospects are not actively researching your product.",
            action:
              "Awareness Campaigns: Focus on raising awareness and interest.",
          },
        ],
      },
    ],
  };

  const flattenedFAQs = Object.keys(faqData).flatMap((topic) => {
    return faqData[topic].map((faq) => {
      if (faq.rows) {
        return {
          ...faq,
          topic,
          tableContent: faq.rows
            .map(
              (row) =>
                `${row.vais} ${row.intent} ${row.insight} ${row.action}`
            )
            .join(" "),
        };
      } else {
        return { ...faq, topic };
      }
    });
  });

  const filteredFAQs = () => {
    return flattenedFAQs.filter(
      (faq) =>
        faq.question?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.answer?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.tableContent?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setActiveKey(null); // Reset active key when searching
    if (e.target.value == "") {
      setActiveKey("ideal-customer-profile");
    }
  };

  const handleSelect = (eventKey) => {
    setSearchQuery("");
    setActiveKey(eventKey); // Update active key when a topic is clicked
  };

  return (
    <>
      <div className="mb-4">
        <div className="row faq_main">
          <div className="col-md-9 col-sm-12">
            <h3>Frequently Asked Questions (FAQs)</h3>
          </div>
          <div className="col-md-3 col-sm-12 faq_block">
            <input
              type="text"
              placeholder="Search FAQs..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="pageContentBlock faqsPage">
        <Tab.Container id="faqs" activeKey={activeKey} onSelect={handleSelect}>
          <div className="row">
            <div className="col-12 col-md-4 col-lg-3">
              <div className="faqCard">
                <h5>FAQ Topics</h5>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="ideal-customer-profile">
                      Ideal Customer Profile (VAIS)
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="account-based-marketing">
                      Account Based Marketing (ABM)
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="look-a-likes">
                      Look-Alike (LAL)
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="prospects">Prospect Downloads</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="data-security">Data Security</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="other-topics">Other Topics</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="predictive-analytics">
                      Predictive Analytics
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="natural-language-processing">
                      Natural Language Processing (NLP)
                    </Nav.Link>
                  </Nav.Item>{" "}
                  <Nav.Item>
                    <Nav.Link eventKey="machine-learning">
                      Machine Learning
                    </Nav.Link>
                  </Nav.Item>{" "}
                  <Nav.Item>
                    <Nav.Link eventKey="data-management">
                      Data Management
                    </Nav.Link>
                  </Nav.Item>{" "}
                  <Nav.Item>
                    <Nav.Link eventKey="intent-data">Intent Data</Nav.Link>
                  </Nav.Item>{" "}
                  <Nav.Item>
                    <Nav.Link eventKey="suppression-template">
                      Suppression Template
                    </Nav.Link>
                  </Nav.Item>{" "}
                  <Nav.Item>
                    <Nav.Link eventKey="packages">Packages</Nav.Link>
                  </Nav.Item>{" "}
                  <Nav.Item>
                    <Nav.Link eventKey="support-tickets">
                      Support Tickets
                    </Nav.Link>
                  </Nav.Item>{" "}
                  <Nav.Item>
                    <Nav.Link eventKey="best-practices">
                      Best Practices for Intent Topic Selection
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="vais-intent">
                      Interpreting VAIS + Intent
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-9">
              <div className="faqContentCard">
                {searchQuery ? (
                  <Accordion defaultActiveKey="0">
                    {filteredFAQs().map((faq, index) => (
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>{faq.question || faq.title}</Accordion.Header>
                        <Accordion.Body>
                        {faq.answer ? (
                            <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                          ) : (
                            <div>
                              <h5>{faq.title}</h5>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    {faq.headers.map((header, index) => (
                                      <th key={index}>{header}</th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {faq.rows.map((row, index) => (
                                    <tr key={index}>
                                      <td>{row.vais}</td>
                                      <td>{row.intent}</td>
                                      <td>{row.insight}</td>
                                      <td>{row.action}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                ) : (
                  <Tab.Content>
                    {Object.keys(faqData).map((topic) => (
                      <Tab.Pane eventKey={topic} key={topic}>
                        <Accordion defaultActiveKey="0">
                          {faqData[topic].map((faq, index) => (
                            <Accordion.Item
                              eventKey={index.toString()}
                              key={index}
                            >
                              <Accordion.Header>
                                {faq.question || faq.title}
                              </Accordion.Header>
                              <Accordion.Body>
                                {faq.answer ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: faq.answer,
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <table className="table table-bordered">
                                      <thead>
                                        <tr style={{ textAlign: "center", fontWeight: '900'}}>
                                          {faq.headers.map(
                                            (header, index) => (
                                              <th key={index}>{header}</th>
                                            )
                                          )}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {faq.rows.map((row, index) => (
                                          <tr key={index}>
                                            <td style={{ textAlign:"center", width: '15rem'}}>
                                              <span style={{background:row.vais === 'High' ? '#037847' : row.vais === 'Medium' ? '#55bf91' :'rgb(85 191 145 / 41%)',color:"#fff",padding:'0.5em 2rem', borderRadius:"2rem",fontWeight: 'bold' }}>{row.vais == 'High' ? '95 - 80' : row.vais === 'Medium' ? '79 - 60' : '59 - 55'}
                                              </span>
                                            </td>
                                            <td style={{ textAlign:"center", width: '15rem' }}>
                                              <span style={{background:row.intent === 'High' ? '#037847' : row.intent === 'Medium' ? '#55bf91' :'rgb(85 191 145 / 41%)',color:"#fff",padding:'0.5em 2rem', borderRadius:"2rem",fontWeight: 'bold' }}>{row.intent == 'High' ? 'Super Strong' : row.intent === 'Medium' ? 'Very Strong' : 'Strong'}
                                              </span>
                                            </td>
                                            <td>{row.insight}</td>
                                            <td>{row.action}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                )}
              </div>
            </div>
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default Faqs;
