import React from 'react';
import './FreeTrial.css'; // Create a CSS file for styles
import { useNavigate } from 'react-router-dom';
export default function FreeTrial() {
  const navigate = useNavigate();
  return (
      <div className="row freepageContentBlock">
        <div className="col-12 col-lg-12">
          <div className="freecontentWrapper FindProspects">
            <div className="bg-Image">
              <h1 className="text-Overlay">The prospect feature is not available in the free trial. To access prospects details please upgrade your plan.</h1>
              <button className="cta-Button" onClick={()=>{
                navigate("/subscription-plans")
              }}>Get Started</button>
            </div>
          </div>
        </div>
      </div>
   
  );
}
