import React from 'react'
import './FreeTrialPage.css'
import { useNavigate } from 'react-router-dom';
export default function FreeTrialPage() {
    const navigate = useNavigate();
  return (
    <div className="row freepageContentBlock">
      <div className="col-12 col-lg-12">
        <div className="freecontentWrapper FindProspects">
          <div className="bgTable">
            <h1 className="textOverlay">The prospect feature is not available in the free trial. To access prospects details please upgrade your plan.</h1>
            <button className="ctaButton" onClick={()=>{
              navigate("/subscription-plans")
            }}>Get Started</button>
          </div>
        </div>
      </div>
    </div>
  )
}
