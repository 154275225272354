import "rc-slider/assets/index.css";
import React, { useState, useEffect, useCallback } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./FindProspectResult.scss";
import ICPTitle from "../../BuildICP/ICPResult/ICPTitle";
import Information from "../../BuildICP/ICPResult/Information";
import ProspectFilter from "./ProspectFilter";
import ProspectColumns from "./ProspectColumns";
import FindProspectTable from "./FindProspectTable";
import { useDispatch, useSelector } from "react-redux";
import { ITEMS_PER_PAGE } from "../../../utils/constants";
import ExportProspectFile from "../Modals/ExportProspectFile/ExportProspectFile";
import {
  findProspectDetails,
  getStandardUserDetails,
  getUserSubscriptionPlanDetails,
} from "../../../context/actions/User";
import { toast } from "react-toastify";
import InformationStaff from "../../BuildICP/ICPResult/InformationStaff";
import _ from 'lodash';

const FindProspectResult = () => {
  const handle = useFullScreenHandle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state?.user);
  const token = useSelector((state) => state?.user?.userInfo?.token);
  const isStaff = userData.userInfo.is_staff;
  const [staffDetails, setStaffDetails] = useState();
  const userId = userData?.userInfo?.user_id || userData?.userInfo?.user;
  // This data is from redux using for handle reset , to show data according to page change and all
  const prospectResult = useSelector((state) => state?.prospectDetails?.prospectDetails);
  const { state } = useLocation();
  const itemPerPage = ITEMS_PER_PAGE;
  // console.log("state",state)
  const[paylaod,setPayload]=useState()
  //for pagination and search bar
  const [dataToRender, setDataToRender] = useState([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalCount: 0,

  })

  const [searchInput, setSearchInput] = useState("");
  const [getComName, setComName] = useState([]);

  //export selected row
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkSelectAll, setCheckSelectAll] = useState([]);
  //open Modal
  const [openModal, setOpenModal] = useState(false);

  //this dataToRender is for updating the data when the page is changing thats why taken in useState
  //filters for showing data
  const [filters, setFilters] = useState({
    companies: [],
    jobLevel: [],
    jobFunction: [],
    companyRevenue: [],
    location: [],
  });
  const [getAllGeolocation, setGeoLocation] = useState(state?.location.map((data) => ({
    value: data,
    label: data,
  })));

  // console.log(getAllGeolocation);
  // sorting data
  const [sortBy, setSortBy] = useState({
    column: "",
    desc: false,
  });
  // user subscription data
  const [getUserSubscriptionData, setGetUserSubscriptionData] = useState([]);

  // below useEffect for getting user subscription details
  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };
      const companies = prospectResult.data
        ?.filter(
          (item, index, self) =>
            index ===
            self.findIndex((t) => t.company_name === item.company_name)
        )
        .map((item) => ({
          value: item.company_name,
          label: item.company_name,
        }));

      setComName(companies);
      dispatch(
        getUserSubscriptionPlanDetails(payload, token, (result) => {
          if (result.status === 200) {
            setGetUserSubscriptionData(result?.data);
          } else if (result.status === 404) {
            navigate("/subscription-plans");
            toast("Please Buy Subscription Package!", { autoClose: 1200 });
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    if (userData.userInfo.is_staff) {
      let id = userData.userInfo?.user_staff?.user;
      dispatch(
        getStandardUserDetails({ user_id: id }, token, (result) => {
          setStaffDetails(result);
        })
      );
    }
  }, []);

  // Generate an array of page numbers based on the totalPages


  //handle page changes with data
  useEffect(() => {
    if(state){
      setPayload(state)
    }
    if (prospectResult.status === 200) {
      setPagination({
        currentPage: prospectResult.current_page,
        totalPages: prospectResult.page_size,
        totalCount: prospectResult.totalCount,
      })
      setDataToRender(prospectResult.data)
    }
  }, [prospectResult,state])

  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    debounce(input);
  };
  const debounce = useCallback(
    _.debounce(_searchVal => {
      setSearchInput(_searchVal)
      let payload={
        ...paylaod,
        page:1,
        search:_searchVal
      }
      dispatchReduxAction(payload)
    }, 1000),[]);

  const handleExportModel = () => {
    setOpenModal(true);
  };

  
  const dispatchReduxAction = (payload) => {
    dispatch(
      findProspectDetails(payload, token, (result) => {
        if (result?.status === 200) {
          handleResetFilters()
        } 
      })
    );
  };






  const onClose = () => {
    setOpenModal(false);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: Array.isArray(prevFilters[filterType])
        ? prevFilters[filterType].includes(value)
          ? prevFilters[filterType].filter((item) => item !== value)
          : [...prevFilters[filterType], value]
        : value,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      companies: [],
      jobLevel: [],
      jobFunction: [],
      companyRevenue: [],
      location: []
    });
    setDataToRender(prospectResult.data)


  };

  const handleApplyFilters = () => {
    let filtered = prospectResult.data;
    // Filter by Main Industry
    if (
      filters.companies.length > 0 &&
      filters?.companies[filters.companies.length - 1].length > 0
    ) {
      filtered = filtered.filter((data) =>
        filters.companies[filters.companies.length - 1].includes(
          data.company_name
        )
      );
    }

    // Compare with the job level
    if (
      filters.jobLevel.length > 0 &&
      filters.jobLevel[filters.jobLevel.length - 1].length > 0
    ) {
      filtered = filtered.filter((data) =>
        filters.jobLevel[filters.jobLevel.length - 1].includes(data.joblevel)
      );
    }

    // Compare with the job function
    if (
      filters.jobFunction.length > 0 &&
      filters.jobFunction[filters.jobFunction.length - 1].length > 0
    ) {
      filtered = filtered.filter((data) =>
        filters.jobFunction[filters.jobFunction.length - 1].includes(
          data.jobfunction
        )
      );
    }

    if (
      filters.location.length > 0 &&
      filters.location[filters.location.length - 1].length > 0
    ) {
      filtered = filtered.filter((data) =>
        filters.location[filters.location.length - 1].includes(
          data.country
        )
      );
    }

    // Compare with the Company Revenue range
    if (
      filters?.companyRevenue?.length > 0 &&
      filters?.companyRevenue?.[filters.companyRevenue?.length - 1]?.length > 0
    ) {
      // Trim spaces from the last companyRevenue array
      const trimmedCompanyRevenue = filters.companyRevenue[
        filters.companyRevenue.length - 1
      ].map((revenue) => revenue.replace(/\s+/g, ""));

      filtered = filtered.filter((data) =>
        trimmedCompanyRevenue.includes(data.revenue.trim())
      );
    }





    // setProspectData(filtered);
    // setCurrentPage(page);
    // setTotalPages(newTotalPages);
    setDataToRender(filtered);
  };

  const handleRangeChange = (type, values) => {
    setFilters({
      ...filters,
      [type]: values,
    });
  };
  //sorting function
  const handleSort = (column) => {
    setSortBy((prevSortBy) => ({
      column,
      desc: column === prevSortBy.column ? !prevSortBy.desc : false,
    }));
  };
  //sorting function
  const sortedData = [...dataToRender]?.sort((a, b) => {
    const columnA = a[sortBy.column];
    const columnB = b[sortBy.column];

    if (columnA < columnB) return sortBy.desc ? 1 : -1;
    if (columnA > columnB) return sortBy.desc ? -1 : 1;
    return 0;
  });

  /* Column Visibility */
  const [columnVisibility, setColumnVisibility] = useState({
    firstName: true,
    lastName: true,
    jobTitle: true,
    jobFunction: true,
    jobLevel: true,
    companyName: true,
    revenue: true,
    companySize: true,
    main_industry_name: true,
    country:true
  });

  const toggleColumnVisibility = (columnName) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName],
    }));
  };
  const handlePageChange = (page) => {
    let payload={
      ...paylaod,
      page:page,
      search:searchInput
    }
    
    dispatchReduxAction(payload)
    const checkExistSelect = checkSelectAll.includes(page);
      setSelectAll(checkExistSelect);
    setPagination((prev) => ({
      ...prev,
      currentPage: page

    }))

  }
  const handleCheckboxChange = (id,data) => {
   
    if (selectedRows.some((row) => row.id === id)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowId) => rowId.id !== id)
      );
      setSelectAll(false); // Uncheck the header checkbox if any individual checkbox is unchecked
    } else {
      let payload={id,replica_id:data?.replica_id}
      if (data.replica_id) {
        paylaod['replica_id'] = data.replica_id

      }
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, payload]);

      if (selectedRows.length + 1 === sortedData.length) {
        setSelectAll(true); // Check the header checkbox if all individual checkboxes are checked
      }
    }
  };
  
  const handleSelectAll = () => {
    // Toggle the selectAll state
    setSelectAll((prevSelectAll) => !prevSelectAll);

    // Update the selectedRows based on the selectAll state

    const allRowIds = sortedData.map((data) => {
      let paylaod = {
        id: data.id,
      }
      if (data.replica_id) {
        paylaod['replica_id'] = data.replica_id
        
      }
      
      return paylaod
    });

    if (!selectAll) {
      // Add rows from the current page to selectedRows
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        ...allRowIds.filter(
          (row) => !prevSelectedRows.some((prevRow) => prevRow.id === row.id)
        ),
      ]);

      // Add the current page to checkSelectAll
      setCheckSelectAll((prevCheckSelectAll) => [
        ...prevCheckSelectAll,
        pagination.currentPage,
      ]);
    } else {
      // Remove rows from the current page from selectedRows
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (row) => !allRowIds.some((newRow) => newRow.id === row.id)
        )
      );

      // Remove the current page from checkSelectAll
      setCheckSelectAll((prevCheckSelectAll) =>
        prevCheckSelectAll.filter((page) => page !== pagination.currentPage)
      );
    }
  };
  return (
    <>
      {openModal ? (
        <ExportProspectFile
          showModal={openModal}
          onClose={onClose}
          prospectData={dataToRender}
          selectedRows={selectedRows}
          type="Prospect"
        />
      ) : null}
      <div className="pageHeadingBlock commonHeading">
        <ICPTitle title="Prospect Results" backTo="/find-prospects" />
        {!isStaff ? (
          <Information getUserSubscriptionData={getUserSubscriptionData} />
        ) : (
          <InformationStaff staffDetails={staffDetails} />
        )}{" "}
      </div>
      <div className="pageContentBlock">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="contentWrapper findProspectDetails">
              <div className="tableHeadingBlock">
                <div className="leftContent">
                  <form className="searchForm">
                    <div className="form-group m-0">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        placeholder="Search"
                        onChange={handleSearchInputChange}
                      />
                      <button type="submit">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.0625 14.0625L17.8125 17.8125"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.375 15.3125C12.6542 15.3125 15.3125 12.6542 15.3125 9.375C15.3125 6.09581 12.6542 3.4375 9.375 3.4375C6.09581 3.4375 3.4375 6.09581 3.4375 9.375C3.4375 12.6542 6.09581 15.3125 9.375 15.3125Z"
                            stroke="#414141"
                            strokeOpacity="0.8"
                            strokeWidth="1.875"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                  <div className="filtersBlock">
                    <ProspectFilter
                      getComName={getComName}
                      getAllGeolocation={getAllGeolocation}
                      filters={filters}
                      setFilters={setFilters}
                      handleFilterChange={handleFilterChange}
                      handleResetFilters={handleResetFilters}
                      handleApplyFilters={handleApplyFilters}
                      handleRangeChange={handleRangeChange}
                    />
                    <ProspectColumns
                      toggleColumnVisibility={toggleColumnVisibility}
                      columnVisibility={columnVisibility}
                      setColumnVisibility={setColumnVisibility}
                    />
                    <div className="tableFullScreen">
                      <div
                        className="fullScreenShow d-flex align-items-center"
                        onClick={handle.enter}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M3.33301 7.4987V4.9987C3.33301 4.55667 3.5086 4.13275 3.82116 3.82019C4.13372 3.50763 4.55765 3.33203 4.99967 3.33203H7.49967M16.6663 12.4987V14.9987C16.6663 15.4407 16.4907 15.8646 16.1782 16.1772C15.8656 16.4898 15.4417 16.6654 14.9997 16.6654H12.4997M12.4997 3.33203H14.9997C15.4417 3.33203 15.8656 3.50763 16.1782 3.82019C16.4907 4.13275 16.6663 4.55667 16.6663 4.9987V7.4987M7.49967 16.6654H4.99967C4.55765 16.6654 4.13372 16.4898 3.82116 16.1772C3.5086 15.8646 3.33301 15.4407 3.33301 14.9987V12.4987"
                            stroke="#414141"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>{" "}
                        Full Screen
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightContent">
                  <div className="paginationBlock">
                    <span className="pagination-page-block">
                      <strong>Total Records : {pagination?.totalCount}</strong>
                    </span>{" "}
                    <div className="d-flex align-items-center">
                      <span
                        style={{ marginLeft: "5rem" }}
                        className="page-span"
                      >
                        {" "}
                        Page :
                      </span>
                      <div className="dropdown paginationDropdown">
                        <div
                          className="pageDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {/* <input
                            type="text"
                            className="pageNumber"
                            placeholder={currentPage}
                          /> */}
                          <button className="page-drop">{pagination.currentPage}</button>
                        </div>
                        <div className="dropdown-menu">
                          <ul>
                            {pagination && pagination.totalPages > 0 && Array.from({ length: pagination.totalPages }, (_, index) => (
                              <li
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)} // Handle page change
                                style={{ cursor: 'pointer' }}
                              >
                                {(index + 1).toString().padStart(2, "0")} {/* Format page number */}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      of
                      <span className="totalPages"> {pagination.totalPages} </span>
                    </div>
                    <div className="pagination">
                      <div className="prev paginationItem">
                        <Link to="" className="paginationLink" onClick={() => {
                          if (pagination.currentPage > 1) {
                            handlePageChange(pagination.currentPage - 1);
                          }
                        }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"

                          >
                            <path
                              d="M4.75 9.25C4.65146 9.25046 4.55382 9.23124 4.46281 9.19347C4.37179 9.15569 4.28924 9.10011 4.22 9.03L0.72 5.53C0.57955 5.38937 0.50066 5.19875 0.50066 5C0.50066 4.80125 0.57955 4.61063 0.72 4.47L4.22 1C4.36096 0.908609 4.52852 0.867188 4.69582 0.882376C4.86312 0.897563 5.02048 0.96848 5.14268 1.08376C5.26488 1.19904 5.34483 1.352 5.36973 1.51814C5.39463 1.68428 5.36303 1.85396 5.28 2L2.28 5L5.28 8C5.42045 8.14063 5.49934 8.33125 5.49934 8.53C5.49934 8.72875 5.42045 8.91937 5.28 9.06C5.13527 9.19069 4.9448 9.25897 4.75 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                      {/* <div
                        className={`next paginationItem${
                          currentPage >
                          getUserSubscriptionData?.user_page_visibility
                            ? "disabled"
                            : ""
                        }`}
                      > */}
                      <div className="next paginationItem">
                        <Link to="" className="paginationLink" onClick={() => {
                          if (pagination.totalPages > pagination.currentPage) {
                            handlePageChange(pagination.currentPage + 1);
                          }
                        }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"

                          >
                            <path
                              d="M1.25 9.25C1.14929 9.24661 1.05034 9.22269 0.959199 9.1797C0.868063 9.13671 0.786667 9.07556 0.72 9C0.57955 8.85937 0.50066 8.66875 0.50066 8.47C0.50066 8.27125 0.57955 8.08062 0.72 7.94L3.72 4.94L0.72 1.94C0.660676 1.79599 0.647659 1.63708 0.682755 1.48533C0.717851 1.33359 0.799319 1.19653 0.915851 1.09319C1.03238 0.989854 1.1782 0.925358 1.33305 0.908658C1.4879 0.891957 1.64411 0.923882 1.78 1L5.28 4.5C5.42045 4.64063 5.49934 4.83125 5.49934 5.03C5.49934 5.22875 5.42045 5.41938 5.28 5.56L1.78 9C1.71333 9.07556 1.63194 9.13671 1.5408 9.1797C1.44966 9.22269 1.35071 9.24661 1.25 9.25Z"
                              fill="#414141"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FullScreen handle={handle}>
                <FindProspectTable
                  prospectData={dataToRender}
                  columnVisibility={columnVisibility}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  handleSelectAll={handleSelectAll}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSort={handleSort}
                  sortedData={sortedData}
                  sortBy={sortBy}
                  currentPage={pagination.currentPage}
                  getUserSubscriptionData={getUserSubscriptionData}
                />
              </FullScreen>
            </div>
            <div className="tableSelectionRecord">
              <div className="selectedItems">
                <h6>{`${selectedRows.length} Item${selectedRows.length !== 1 ? "s" : ""
                  } Selected`}</h6>

                <p>
                  <strong>Note:</strong> One credit will be deducted for each
                  entry exported from the Prospect results.
                </p>
              </div>
              <div className="exportItems">
                <Link
                  to=""
                  className={`btn ${selectedRows.length === 0 ? "disabled" : ""
                    }`}
                  onClick={handleExportModel}
                >
                  {/* <i
                    className="pi pi-cloud-download"
                    style={{ fontSize: "2rem", marginRight: "8px" }}
                  ></i> */}
                  Export
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindProspectResult;
